/* verze s gridem */
.footer-wrapper {
  background-color: #404646;
  color: white;
  padding: 1rem 0;
}

a:link {
  color: white;
}
a:hover {
  text-decoration: underline;
}
a:visited {
  color: white;
}

/* WORK - TEST */
/*
.footer-wrapper {
  background-color: lightpink;
}
*/
