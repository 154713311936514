/* verze s gridem */
.contact-info-wrapper {
  width: 100%;
  background-color: #404646;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.contact-container {
  padding: 0 20%;
}

.contact-name {
  grid-row: 1/2;
  grid-column: 1/2;
}

.contact-phone-mail-fb {
  grid-row: 1/2;
  grid-column: 2/3;
}

/* FORMAT */
.contact-info-wrapper > div {
  padding: 1rem;
}

.icon {
  font-size: 1.5rem;
  margin-right: 0.75rem;
  position: relative;
  top: 5px;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #79b1bb;
  padding-left: 1rem;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1200px) {
  .contact-container {
    padding: 0 10%;
  }
}

@media screen and (max-width: 900px) {
  .contact-container {
    padding: 0 5%;
  }
}

@media screen and (max-width: 790px) {
  .contact-info-wrapper {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .contact-name {
    grid-row: 1/2;
    grid-column: 1/2;
  }

  .contact-phone-mail-fb {
    grid-row: 2/3;
    grid-column: 1/2;
  }
}

@media screen and (max-width: 400px) {
  .contact-info-wrapper {
    padding-right: 0.5rem;
  }

  .contact-name {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .contact-phone-mail-fb {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .contact-container {
    padding-right: 0;
  }

  h3 {
    padding-left: 0.5rem;
  }
}

/* WORK - TEST */
/*
.contact-info-wrapper > div {
  background-color: rgb(129, 129, 129);
}

.contact-info-wrapper {
  background-color: lightskyblue;
}
*/
